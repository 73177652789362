<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <tchtree v-model="searchForm.class_ids" ref="classTree"></tchtree>

        <el-input placeholder="学号或姓名" v-model="searchForm.keyword" size="small" class="input-with-select"
          style="width:200px;" clearable></el-input>

        <el-date-picker
            v-model="date_range_value"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期" style="margin-left: 10px;">
        </el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList()}">搜索</el-button>
        <el-button icon="el-icon-plus" style="margin-left: 10px;" type="primary" size="small" @click="addNew">新增</el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button v-if="$store.state.config.sync_data" size="mini" icon="el-icon-refresh" @click="tongbuJava"
          type="primary" plain style="margin-left: 10px;">迁移老版本</el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>
     <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <el-table-column prop="stu_code" label="学号" width="150"></el-table-column>
      <el-table-column prop="stu_name" label="学生姓名" width="150"></el-table-column>
      <el-table-column prop="back_date" label="返校时间" width="200"></el-table-column>
      <el-table-column prop="yx_name" label="系部"></el-table-column>
      <el-table-column prop="class_name" label="班级"></el-table-column>
      <el-table-column prop="teacher_name" label="班主任"></el-table-column>
       <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog ref="ruleForm" title="学生返校情况" :visible.sync="showFormDialog" :close-on-click-modal="false"
               :modal-append-to-body="false" @close="showFormDialog = false" :destroy-on-close="true" custom-class="cus_dialog">

      <el-form :model="EditItem" label-width="120px" :rules="rules" ref="formData">
        <el-form-item label="学号" prop="stu_id">
          <el-select v-model="EditItem.stu_id" :disabled="dialogxx" filterable remote reserve-keyword
                     placeholder="请输入学号" :remote-method="remoteMethod" :loading="loading"
                     @change="checkedxz" @focus="focusxz">
            <el-option v-for="item in options" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生姓名">
          <el-input v-model="EditItem.stu_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="班级名称">
          <el-input v-model="EditItem.class_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="返校时间" prop="back_date">
          <el-date-picker
              v-model="EditItem.back_date"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择返校时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="EditItem.remark" type="textarea" :autosize="{minRows: 2}"></el-input>
        </el-form-item>
      </el-form>
      <div class="" slot="footer">
        <el-button @click="onSubmit()" type="primary">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";
export default {
  components: {
    tchtree
  },
  data() {
    return {
      showFormDialog: false,
      dialogxx: false,
      loading: false,
      thisTeacherid: 0,
      dialogshow: false,
      date_range_value: "",
      searchForm: {
        class_ids: "",
        keyword: ""
      },
      EditItem: {
        id: 0,
        back_date: moment().format('YYYY-MM-DD')
      },
      DataList: [],
      ClassList: [],
      options: [],
      optionsRow: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      rules: {
        stu_id: [{required: true, message: "请输入学号", trigger: 'change'}],
        back_date: [{required: true, message: "请选择返校时间", trigger: "change"}]
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http
        .post("/api/stu_return_to_school_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          class_ids: this.searchForm.class_ids,
          keyword: this.searchForm.keyword,
          start_date: this.date_range_value ? this.date_range_value[0] : '',
          end_date: this.date_range_value ? this.date_range_value[1] : '',
        })
        .then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .post("/api/stu_return_to_school_delete", { id: row.id })
            .then(res => {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            });
          this.getList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem = {
        id: 0,
        back_date: moment().format('YYYY-MM-DD')
      }
      this.showFormDialog = true;
    },
    saveJh() {
      this.EditItem = {
        class_id: 0,
        id: 0,
        title: "",
        class_name: "",
        teacher_name: "",
        teacher_id: 0,
        teacher_code: "",
        plan_desc: "",
        title1: "",
        plan_time: "新增实习计划",
        sort: 99,
        type: 1
      };
      this.dialogshow = true;
    },
    viewInfo(row) {
      this.EditItem = { ...row };
      this.EditItem.title1 = "实习计划详情";
      this.dialogshow = true;
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_back_to_school", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.$http
            .post("/api/students_class_info", { stu_code: query })
            .then(res => {
              this.loading = false;
              if (res.data) {
                this.options = [{ value: res.data.id, label: res.data.stu_name }];
                this.optionsRow.stu_code = res.data.stu_code;
                this.optionsRow.stu_id = res.data.id;
                this.optionsRow.stu_name = res.data.stu_name;
                this.optionsRow.class_name = res.data.class_name;
              }
            });
      } else {
        this.options = [];
      }
    },
    checkedxz() {
      this.dialogxx = false;
      this.EditItem.stu_code = this.optionsRow.stu_code;
      this.EditItem.stu_id = this.optionsRow.stu_id;
      this.EditItem.stu_name = this.optionsRow.stu_name;
      this.EditItem.class_name = this.optionsRow.class_name;
    },
    focusxz() {
      this.options = [];
      this.optionsRow.stu_code = "";
      this.optionsRow.stu_id = "";
      this.optionsRow.stu_name = "";
      this.optionsRow.class_name = "";
    },
    onSubmit() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$http.post('/api/stu_return_to_school_edit', this.EditItem).then(res => {
            this.$message.success('提交成功')
            this.showFormDialog = false
            this.$refs.formData.resetFields()
            this.getList()
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>

<style scoped="scoped">
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
